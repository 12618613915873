.tabla-dis-doc {
    font-family: arial, sans-serif;
    font-family: open-Sans;
    font-size: 88px;
    border-collapse: collapse;
    width: 98%;
    border: 1px solid black;
    margin: auto;
    padding-top: 50px;
    thead {
      color: black;
      background-color: white;
      text-align: left;
    }
  
    th,
    td {
      font-size: 18px;
      font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
        "Arial", "sans-serif";
    }

    tbody >tr , td{
        border: 1px solid black;
        color: black;
    }
  }

  .materias-menu {
    text-align: left;
  }

  .hide {
    visibility: hidden;
    border: none;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 24px;
}