.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 2px;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.button1 {
  background-color: #4caf50;
  color: white;
  border: 2px solid #4caf50;
}

.button:hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.agregareditarbuttons {
  cursor: pointer;
}

.agregareditarbuttons:hover {
  text-decoration: underline;
}
.formulario {
  padding: 0px 100px;
  display: inline-block;
  width: 100%;
  .ant-divider-inner-text {
    color: #661e2f;
  }
}

.form-wrapper {
  width: 100%;
  .card-header {
    color: white;
    background-color: #661e2f;
  }
}

.iconEnable {
  background-color: #661e2f;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  height: 35px;
  justify-content: center;
  text-align: center;
  padding: 10px;
}
.iconDisable {
  background-color: silver;
  color: white;
  cursor: no-drop;
  border-radius: 8px;
  height: 35px;
  padding: 10px;
  // justify-content: center;
  text-align: center;
}

.totales-totales {
  // border-top: solid 1px black;
  padding-top: 5px;
  text-align: center;
  color: #661e2f;
}

table {
  border-bottom: grey;
  font: 18px "Open Sans", Helvetica, Arial, sans-serif;
}
.tabla-firma {
  width: 300px;
  font-size: 18px;
  margin-top: 60px;
  //  border: 1px ;
  // bo7arder-top:1px solid black;
  tbody > tr {
    border-top: 2px solid black; // padding: 5px;
    border-bottom: 10n.4&a11 none;
  }
  td {
    padding: 10px;
    border-top: 1px solid black;
    border-bottom: none;
  }
}
thead > tr {
  color: white;
  background-color: #661e2f;
  text-align: center;
}

th,
td {
  padding: 10px 6px;
}

tbody > tr {
  border-bottom: 1px solid#dedede;
  text-align: center;
  td.nombre {
    text-align: left;
  }
}

tbody > tr:hover {
  background-color: #efe6e6;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  background-color: #661e2f;
  border-color: black;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:hover {
  border-color: #661e2f;
  transform: scale(1.1, 1.1);
}

// .ant-btn {
//   background-color: #661e2f;
//    color:white;
//    &:focus{
//      outline: none;
//      border:none;
//     }

// }

// .ant-btn:hover {

//   border:none;
//   transform: scale(1.1,1.1)
// }

.titulo-header {
  color: white;
  font-size: 18px;
  background-color: #661e2f;
  text-align: center;
  margin-bottom: 30px;
}

.datos-laborales-fecha{
  .ant-picker-input{
    font-size: 18px;
  }
}


.datos-laborales {
  .ant-col {
    font-size: 18px;
  }
  .ant-form-item-label {
    label {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
