.tabla-alta-centro-supervision {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 98%;
    height: 98%;
    // border: 1px solid black;
    // border-color: black;
    // margin: auto;
    // padding-top: 50px;
      th{
      color: white;
      // background-color: white;
      text-align: center;
    // border: 1px solid black;
    // border-color: black;

      }
      
      
       td {
        border: 1px solid black;
        vertical-align: top;
    padding:5px;
    // text-align: center;
      }


      // .fecha-elaboracion {

        textarea {
          border:0;
          width: 100%;
          text-align: center;
          border:none;
  outline:none;
  overflow: hidden;
  resize: none;
        }
      
    
    }

      .wrapper-supervision {
        display:grid;
        grid-template-columns: repeat(20,5%);
        grid-template-rows: repeat(20,5%);
        // row-gap: 15px;
        // column-gap: 15px;
        // overflow: scroll;
        // width: 11in;
        // height:8.5in ;
        // margin: 5px auto;
      // background-color: red;

      
      };

      .banner-inspeccion {
        grid-area: 1/3/3/19;

        margin-top:10px
      }
      .identificacion {
        grid-area: 7/3/10/12;
      }
      .personal-supervision {
        grid-area: 14/3/16/10;
      }
      .concentrado-personal {
        grid-area: 7/12/18/19 ;
      }
      .fecha-elaboracion {
        grid-area: 20/3/21/19;
      }