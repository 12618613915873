.tabla-alta-centro {
  font-family: arial, sans-serif;
  font-family: open-Sans;
  font-size: 18px;
  border-collapse: collapse;
  width: 98%;
  border-color: black;
  margin: auto;
  padding-top: 50px;
  thead {
    color: black;
    background-color: white;
    text-align: left;
  }

  th,
  td {
    font-size: 18px;
    font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
      "Arial", "sans-serif";
  }
}

// table {
//   table-layout: auto;
// }

.wrapper {
  display: grid;
  grid-template-columns: repeat(40, 2.5%);
  grid-template-rows: repeat(40, 2.5%);
  // row-gap: 15px;
}
.w-identificacion {
  grid-area: 1/1/5/41;
}
.w-infraestructura {
  grid-area: 6/1/8/41;
}
.w-estructura-ocupacional {
  grid-area: 10/1/20/21;
}

.w-tecnologias {
  grid-area: 18/22/30 /31;
}
.w-artisticas {
  grid-area: 25/32/31/41;
}
.w-numero-grupos {
  grid-area: 18/32/24/45;
}

.w-numero-alumnos {
  grid-area: 10/22/17/41;
}

.w-firmas {
  grid-area: 31/1/35/41;
}

button:disabled,
button[disabled] {
  border: 1px solid #661e2f;
  background-color: red;
  color: #ffffff;
}

button :hover {
  cursor: pointer;
}

.identificacion-alta-centro {
  textarea {
    border: none;
    outline: none;
    overflow: hidden;
    resize: none;
    text-align: center;
  }
}

.tabla-alta-centro td {
  border: 1px solid black;
  vertical-align: top;
  padding: 5px;
}

.tabla-artisticas {
  width: 310px;
}
.tabla-alta-centro-1 {
  // margin: 10px;

  vertical-align: top;
  // width:98%
}

.tabla-alta-centro-1 th {
  border: 1px solid black;
  // text-align: center;
  padding: 8px;
  background-color: #661e2f;
  color: white;
}

.tabla-alta-centro-1 td {
  border: 1px solid black;
  vertical-align: top;
  padding: 5px;
  // text-align: center;
}
.tabla-alta-centro-1 select {
  border: 0;
  width: 60px;
}

// .arte {
//   margin-right: 5px;
//   margin-left: 5px;
// }
.tabla-alta-centro-1 input:not([class^="arte"]) {
  display: inline;
  width: 100%;
  height: 100%;
  // background-color: transparent;

  border: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: none;
  background-color: white;
  outline: none;
  padding: 0;
  text-align: center;
}

.tabla-alta-centro-1:focus {
  outline: none;
}

.tabla-alta-centro-1 tr:nth-child(even) {
  background-color: white;
}

.tabla-alta-centro-1 tr:hover {
  background-color: #abdbe3;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  //position: absolute;
  width: 100%;
  // top: 12px;
  // right: 30px;
  // width: 1280px;
  //height: 100%;
  //float:none;
  display: block;
  // vertical-align: middle;
  cursor: pointer;
  // border-radius: 50px;
  //margin: 0 auto;
}
.tabla-alta-centro-firmas {
  // margin: 10px;
  width: 98%;
  margin: 17px;
  vertical-align: top;

  textarea {
    border: 0;
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    overflow: hidden;
    resize: none;
  }
}
.tabla-alta-centro-art {
  // margin: 10px;
  width: 270px;
  // margin: 17px;
  // vertical-align: top;
}
