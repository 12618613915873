.home-wrapper {
    .card-header {
        color: white;
        background-color: #661e2f;
        text-align: center;
    }
    .card-body {
      height: 81vh;
      overflow: auto;
    }
    .ant-divider-inner-text {
      color: #661e2f;

    }
    table {
      th {
        font-size: 13px;;
      }
      tr {
        td {
          font-size: 12px;;
        }
      }
      &.estructura {
        tr {
          td:first-child {
            font-weight: bold;
          }
        }
      }
    }

    .img_portada_c{
      display: table-cell;
      height: 10px;
      width: 10%;
    }
    .img_portada_2{
      max-width: 1400px;
      margin-top: 7vh;
    }
}