.Actualizado {
    background-color: green;
    color: white;
border-color: grey;
cursor: default;
  }
  .Eliminado {
    background-color: red;
    color: white;
border-color: grey;
cursor: default;


  }
  .Insertado {
    background-color: orange;
    color: white;
border-color: grey;
cursor: default;


  }