.alta-personal-imprimir {
  padding-top: 100px;
  text-align: left;
  // margin-left: auto;
  // margin-right: auto;
}

// @media print {
//     .logo-container,
//     .img-wrapper,
//     img {
//       max-height: none !important;
//       height: 100% !important;
// }

.boton-imprimir {
  margin-left: 20px;
  margin-top: 3px;
}

button {
  cursor: pointer;
}

.grayed-out {
  pointer-events: none;
  opacity: 0.3;
}

.num-pagina {
  float: right;
  font-size: 18px;
  position: sticky;
  width: 100px;
  color: white;
}
