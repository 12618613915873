#section {
    text-align: right;
}

#header {
    color: white !important;
    background-color: #661e2f !important;
    height: 300px;
}
#layout {
    background-color: white;
}
 
.ant-form{
    margin: 25px !important;
} 

.title {
    text-align: center;
    padding-top: 20px;
}