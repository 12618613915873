// .ant-picker-panel-container{
//   display:none;
// }

.app-layout {
  text-align: right;
  height: 100vh;
  background-color: white;

  .header {
    height: 80px;
    line-height: 30px;
    margin-top: 15px;
    .bienvenido {
      width: 100px;
      float: left;
    }
    background-color: white;
    .google-user-img {
      position: absolute;
      top: 12px;
      right: 30px;
      width: 0px; /* Modificar el tamaño para que aparezca la imagen superior derecha [logo google] */
      height: 100px;
      float: right;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50px;
    }
    a {
      float: right;
      margin-right: 60px;
      font-size: 20px;
    }
    .ant-menu-item-selected {
      border-bottom: 2px solid #661e2f !important;
      a {
        color: #661e2f !important;
      }
    }
    .ant-menu-item-active {
      &:hover {
        border-bottom: 2px solid #661e2f !important;
        a {
          color: #661e2f !important;
          text-decoration: none;
        }
      }
    }
  }
  .main {
    padding: 10px 20px;
    background-color: white;
    height: 70vh;
    overflow: auto;
  }
  footer {
    background-color: white;
    color: #661e2f;
    text-align: center;
    padding: 3px;
    background-color: #661e2f;
    color: white;
  }

  /* Clearing floats */
  .cf:before,
  .cf:after {
    content: " ";
    display: table;
  }

  .cf:after {
    clear: both;
  }

  .cf {
    *zoom: 1;
  }

  /* Mini reset, no margins, paddings or bullets */
  .menu,
  .submenu {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  /* Main level */
  .menu {
    margin: 5px auto;
    width: 800px;
    /* http://www.red-team-design.com/horizontal-centering-using-css-fit-content-value */
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
  }

  .menu > li {
    background: #830f2b;
    float: left;
    position: relative;
    transform: skewX(25deg);
  }

  .menu a {
    display: block;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    font-family: Arial, Helvetica;
    font-size: 14px;
  }

  .menu li:hover {
    background: #853246;
  }

  .menu li .active {
    color: silver;
  }

  .menu > li > a {
    transform: skewX(-25deg) translateY(-7px);
    padding: 0px 60px;
    height: 50px;
  }
}

.toast-conntainer {
  width: 500px;
  .Toastify__toast--error {
    background: white;
    color: black;
  }
  .Toastify__toast {
    border-radius: 10px;
  }
  .Toastify__toast-body {
    padding-left: 15px;
  }
}

.login-button {
  padding: 10px;
  .banner {
    width: 100%;
  }
  .google-login {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.ant-picker-dropdown {
  .ant-picker-content {
    tr {
      th {
        background-color: white;
      }
    }
  }
}
